.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiTiptap-RichTextField-root {
  margin: 15px;
}

.MuiTiptap-ControlledBubbleMenu-root {
  z-index: 3000 !important;
}

.base-Popper-root {
  z-index: 3000 !important;
}

ul {
	font-size:2em;
}

ul span {
	display:inline-block;
	font-size:0.5em;
	vertical-align:middle;
}

.ResizableImageComponent-image-ResizableImageComponent-imageSelected img.error::before {
  background-color: #ccc;
  content: ' ';
  position: absolute;
  display: inherit;
  width: inherit;
  height: inherit;
  left: 0;
  top: 0;
}
.ResizableImageComponent-image-ResizableImageComponent-imageSelected img.error::after {
  content: attr(alt);
  color: #FFF;
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  top: 50%;
}

.glyphicon-move {
  cursor: move;
  cursor: -webkit-grabbing;
  float: right;
}

.Border-Selected-500 {
  border: 2px solid #11c736;
  border-radius: 5px;
}

.Nav-Link {
  cursor:pointer;
  color:blue;
  text-decoration:underline;
}
.Nav-Link:hover {
  text-decoration:none;
  text-shadow: 1px 1px 1px #555;
}

.Floating-Buttons {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 10px;
}

.Class-Title {
  background: linear-gradient(to left, rgba(255, 255, 255, 0), rgb(101, 172, 233, 38%));
  width: 100%;
  height: 50px;
  align-content: center;
  font-weight: 500;
}

.Module-Title {
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(101, 172, 233, 38%));
  width: 100%;
  margin-right: -80px;
  height: 50px;
  align-content: center;
  font-size: x-large;
  font-weight: 500;
}

.Module-Header-Icon-Background-BR {
  height: 50px; 
  width: 95px;
  border-radius: 7px;
  background: 
  linear-gradient(135deg, transparent 63px, white 0) bottom right,
  linear-gradient(135deg, blue, red);
}

.Module-Header-Icon-Background-RB {
  height: 50px; 
  width: 95px;
  border-radius: 7px;
  background: 
  linear-gradient(135deg, transparent 63px, white 0) bottom right,
  linear-gradient(135deg, red, blue);
}

.Module-Header-Icon-Background-OB {
  height: 50px; 
  width: 95px;
  border-radius: 7px;
  background: 
  linear-gradient(135deg, transparent 63px, white 0) bottom right,
  linear-gradient(135deg, orange, blue)}

  iframe#webpack-dev-server-client-overlay{display:none!important}

.Module-Overview-Details {
  background: linear-gradient(90deg, rgb(206 232 255) 0%, rgb(164 230 255 / 85%) 30%, rgba(0, 212, 255, 1) 100%);
  height: 100%;
  border-radius: 20px;
  box-shadow: rgb(0, 0, 0) 0px 2px 11px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}

.Module-Overview-Detail-Item {
  margin-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}