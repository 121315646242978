.user-agent-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    /* background-color: #121212; */
    /* color: #ffffff; */
    font-family: 'Arial', sans-serif;
    min-height: 50vh;
  }
  
  .info-card {
    /* background-color: #1e1e1e; */
    min-height: 350px;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }
  
  .info-card h2 {
    margin-top: 0;
    /* color: #00adb5; */
  }
  
  .info-card p {
    margin: 5px 0;
  }