.Loading-container { 
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; 
  background-color: #000000;     
  opacity: 0.5;
  }
.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
  }
  