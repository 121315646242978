.maintenance-banner {
    background-color: #f8d7da; /* Light red background */
    color: #721c24; /* Dark red text */
    border: 1px solid #f5c6cb; /* Red border */
    padding: 10px 20px;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2000; /* Ensure banner is on top */
  }
  
  .banner-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }